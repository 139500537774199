import { render, staticRenderFns } from "./GroupCreatedMind.vue?vue&type=template&id=102a3ddb&scoped=true"
import script from "./GroupCreatedMind.vue?vue&type=script&lang=js"
export * from "./GroupCreatedMind.vue?vue&type=script&lang=js"
import style0 from "./GroupCreatedMind.vue?vue&type=style&index=0&id=102a3ddb&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "102a3ddb",
  null
  
)

export default component.exports